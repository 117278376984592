import React from 'react';
import Button from '../../components/Button/Button';
import ShelfList from '../../components/ShelfList/ShelfList';
import './Shelves.scss';

export default () => (
	<div className="container">
		<ShelfList />
		<div className="button-menu">
            <Button className="shelf-button" isFloating icon={['fad', 'plus']}/>
        </div>
	</div>
)