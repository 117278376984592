import React, { ButtonHTMLAttributes } from 'react';
import './Button.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';

interface ButtonInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: any[],
    isFloating?: boolean,
    isClear?: boolean
}

export default observer(({ children, isFloating = false, isClear = false, className = '', icon, ...rest }: ButtonInterface) => (
    <button {...rest} className={
        classNames({
            'btn': true,
            'btn--floating': isFloating,
            'btn--clear': isClear
        }) + (className !== '' ? ' ' + className : '')
    }>{icon ? (<FontAwesomeIcon className="btn__icon" size="2x" icon={[icon[0], icon[1]]}/>) : null}{children}</button>
));