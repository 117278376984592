import React, { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import bookAPI from '../../api/book';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router';
import { useBookStore } from '../../stores';
import BookItem from '../BookItem/BookItem';

export default observer(() => {
	const [ isLoading, setIsLoading ] = useState(true);
	const bookStore = useBookStore();
	const history = useHistory();
	const { id } = useParams();
	if (!id) {
		history.push('/');
	}

	useEffect(() => {
		bookAPI.all(id).then((response) => {
			setIsLoading(false);
			bookStore.set(response);
		})
	}, []);

	return (
		<Loader isLoading={isLoading}>
            <div className="library-list">
                <ul className="library-list__ul">
					{bookStore.books.map(book => (<BookItem key={book.id} libraryId={id} book={book}/>))}
                </ul>
            </div>
        </Loader>
	)
})