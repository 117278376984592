import React from 'react';
import Button from '../../components/Button/Button';
import BookList from '../../components/BookList/BookList';

export default () => (
	<div className="container">
		<BookList />
		<div className="button-menu">
            <Button className="shelf-button" isFloating icon={['fad', 'plus']}/>
        </div>
	</div>
)