import React from 'react';
import { observer } from 'mobx-react-lite';
import LibraryList from '../../components/LibraryList/LibraryList';
import Button from '../../components/Button/Button';
import './Landing.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default observer(() => (
    <div className="container">
        <LibraryList />
        <div className="button-menu">
            <Link to="/library/new" className="button-menu__item library-add-button plus--floating btn btn--floating"><FontAwesomeIcon size="2x" icon={['fad', 'plus']}/></Link>
        </div>
    </div>
))