import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import './globals/scss/main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faAngleLeft, faEllipsisV, faCheckSquare } from '@fortawesome/pro-light-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faBookOpen, faAngleRight as duoAngleRight, faPlus, faPen } from '@fortawesome/pro-duotone-svg-icons';

import { Cookies } from './util';

import userStore from './stores/user-store';

library.add(
    faAngleLeft,
    faEllipsisV,
    faAngleRight,
    faBookOpen,
    duoAngleRight,
    faPlus,
    faPen,
	faCheckSquare,
	faTrash,
)

const preLoading = async () => {
    if (Cookies.get('token')) {
        await userStore.load();
    }
}

preLoading().then(() => {
    ReactDOM.render(
        <App />, 
        document.getElementById('root')
    );
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
