import React from 'react';
import BookInterface from '../../models/book';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import './BookItem.scss';
import { Requester } from '../../util';

interface BookItemInterface {
	book: BookInterface,
	libraryId?: string
}

export default observer(({ book, libraryId } : BookItemInterface) => {
	const apiURL = Requester.baseUrl;

	return (
		<li className="book-item">
			<Link 
				className="a a--black a--bold library-item__link" 
				to={`/book/${book.id}`}>
					<img src={(apiURL ? apiURL : '') + (book.image.path ? book.image.path : '')}/>
				{book.title}
			</Link>
		</li>
	)
});