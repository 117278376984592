import React, { useState, useRef, useEffect } from 'react';
import './LibraryItem.scss';
import LibraryInterface from '../../models/library';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import libraryAPI from '../../api/library';
import { useLibraryStore } from '../../stores';
import { observer } from 'mobx-react-lite';

interface LibraryItemInterface {
    library: LibraryInterface
}

export default observer(({ library } : LibraryItemInterface) => {
	const [ name, setName ] = useState<string>(library.name);
	const [ edit, setEdit ] = useState<boolean>(false);

	const input = useRef<HTMLInputElement>(null);

	const libraryStore = useLibraryStore();

	useEffect(() => {
		if (input.current) {
			input.current.focus();
		}
	}, [edit])

	const toggleEdit = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setEdit(!edit);
	}

	const deleteLibrary = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		libraryAPI.delete(library.id).then(() => {
			libraryStore.remove(library.id);
		});
	}

	const save = () => {
		library.name = name;
		libraryAPI.update(library).then((response) => {
			libraryStore.setItem(response);
			setName(response.name);
		});
		setEdit(false);
	}

	const onEnter = (e: any) => {
		if (e.which === 13) {
			save();
		}
	}	

	const onBlur = (e: any) => {
		save()
	}

	return (
		<li className="library-item">
			<Link 
				className="a a--black a--bold library-item__link" 
				onClick={(e) => { if (edit) e.preventDefault() }}
				to={`/library/${library.id}/books`}>
				{!edit ? 
				(
					<span className="library-item__label">
						{library.name}
					</span>
				)
				:
				(
					<input 
						className="library-item__label library-item__input"
						value={name}
						onChange={(e) => setName(e.target.value)}
						onKeyUp={onEnter}
						onBlur={onBlur}
						type="text"
						ref={input}
						placeholder="name..."/>
				)}
				

				<span className="library-item__menu">
					<button 
						className="reset-button library-item__menu__button" 
						onClick={toggleEdit}>

						<FontAwesomeIcon 
							size="1x" 
							icon={['fad', 'pen']}/>
					</button>
					<button 
						className="reset-button library-item__menu__button" 
						onClick={deleteLibrary}>

						<FontAwesomeIcon 
							size="1x" 
							icon={['fas', 'trash']}/>
					</button>
				</span>
			</Link>
		</li>
	)
});